<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[85] w-full" @click="close">
      <NotificationDialog v-if="showNotificationDialog" @close="closeNotificationDialog"
        :close-dialog="showNotificationDialog" :content="'Successfully resend certificates'" />

      <NotificationDialog v-if="resending" @close="closeNotificationDialog" :close-dialog="showNotificationDialog"
        :content="'Resending certificates'" />
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-untitled-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-[85] overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all lg:my-8 lg:max-w-2xl md:my-8 md:max-w-md sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
              <div class="absolute top-0 right-0  pt-4 pr-6 sm:block">
                <button type="button" class="text-untitled-gray-400" @click="close">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div class="flex mt-3 flex-col md:flex-row  lg:flex-row justify-between">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-untitled-gray-900">
                  Your Certificates
                </DialogTitle>

                <div class="text-base w-auto lg:w-[200px] md:w-[190px]" >
                  <ECombobox
                    v-model="sortBy" 
                    name="sorts" 
                    :options="sorts" 
                    option-name="name" 
                    value-key="value" 
                    placeholder="Sort By" 
                    :multiple="false" 
                    :filterable="false" 
                    :clearable="false"/>
                </div>
              </div>
              <div class="">

                <div class="w-full">
                  <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                    <li class="hidden -mb-px mr-2 last:mr-0 flex-auto text-center">
                      <a class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
                        @click="toggleTabs(0)" :class="{
                          'text-untitled-gray-600 bg-white': openTab !== 0,
                          'text-untitled-gray-800 bg-untitled-gray-200': openTab === 0,
                        }">
                        Curriculums <span v-if="filteredCurriculums.length >= 0" class="text-untitled-gray-500">({{
                          filteredCurriculums.length }})</span>
                      </a>
                    </li>
                    <li class="hidden -mb-px mr-2 last:mr-0 flex-auto text-center">
                      <a class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
                        @click="toggleTabs(1)" :class="{
                          'text-untitled-gray-600 bg-white': openTab !== 1,
                          'text-untitled-gray-800 bg-untitled-gray-200': openTab === 1,
                        }">
                        Courses <span v-if="filteredCourseList.length >= 0" class="text-untitled-gray-500">({{
                          filteredCourseList.length }})</span>
                      </a>
                    </li>
                    <li class="hidden -mb-px mr-2 last:mr-0 flex-auto text-center">
                      <a class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
                        @click="toggleTabs(2)" :class="{
                          'text-untitled-gray-600 bg-white': openTab !== 2,
                          'text-untitled-gray-800 bg-untitled-gray-200': openTab === 2,
                        }">
                        Modules <span v-if="filteredModuleList.length >= 0" class="text-untitled-gray-500">({{
                          filteredModuleList.length }})</span>
                      </a>
                    </li>
                    <li class="hidden -mb-px mr-2 last:mr-0 flex-auto text-center">
                      <a class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
                        @click="toggleTabs(3)" :class="{
                          'text-untitled-gray-600 bg-white': openTab !== 3,
                          'text-untitled-gray-800 bg-untitled-gray-200': openTab === 3,
                        }">
                        Playlist <span v-if="filteredPlaylists.length >= 0" class="text-untitled-gray-500">({{
                          filteredPlaylists.length }})</span>
                      </a>
                    </li>
                  </ul>
                  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded z-0">
                    <div class=" flex-auto">
                      <div class="tab-content tab-space">
                        <div :class="{ hidden: openTab !== 0, block: openTab === 0 }">
                          <LoadingState v-if="loading" class="mx-auto text-center" />
                          <div v-else>
                            <ul v-if="filteredCurriculums.length > 0" role="list" class="overflow-auto"
                              :style="filteredCurriculums.length < 4 ? 'height:auto' : 'height: 600px;'">
                              <li class="grid lg:flex md:flex py-6 px-4 shadow-md" v-for="curriculums in filteredCurriculums"
                                :key="curriculums.id">


                                <div class="ml-0 lg:ml-4 md:ml-4 mt-2 flex flex-1 flex-col sm:ml-6">
                                  <div>
                                    <div class="flex justify-between">
                                      <h4 class="text-sm">
                                        <a href="#" class="font-medium text-gray-700 hover:text-gray-800">{{
                                          curriculums.certificateable?.name }}</a>

                                      </h4>
                                      <p class="ml-4 text-sm font-medium text-gray-900">
                                        <v-button @click="downloadCertificate(curriculums.id, 'curriculum')" type="button"
                                          class="font-medium text-xs mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                          </svg>
                                          Certificate
                                        </v-button>
                                        <v-button @click="resend(curriculums.certificateable_id, 'curriculum')" type="button"
                                          class="font-medium text-xs bg-transparent border-untitled-gray-200"> <svg
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                          </svg>

                                          Resend
                                        </v-button>
                                      </p>
                                    </div>
                                  </div>

                                  <div class="flex flex-1 items-end justify-between pt-5">
                                    <div class="text-xs ">
                                      <p class="text-xs text-left ">Certification Date: <br />{{
                                        formatDate(curriculums.created_at) }}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div v-else class="text-center">
                              <p class="text-untitled-gray-500">No curriculums certification.
                              </p>
                            </div>
                          </div>

                        </div>
                        <div :class="{ hidden: openTab !== 1, block: openTab === 1 }">
                          <LoadingState v-if="loading" class="mx-auto text-center" />
                          <div v-else>
                            <ul v-if="filteredCourseList.length > 0" role="list" class="overflow-auto"
                              :style="filteredCourseList.length < 4 ? 'height:auto' : 'height: 600px;'">
                              <li class="grid lg:flex md:flex py-6 px-4 shadow-md" v-for="course in filteredCourseList"
                                :key="course.id">


                                <div class="ml-0 lg:ml-4 md:ml-4 mt-2 flex flex-1 flex-col sm:ml-6">
                                  <div>
                                    <div class="flex justify-between">
                                      <h4 class="text-sm">
                                        <a href="#" class="font-medium text-gray-700 hover:text-gray-800">{{
                                          course.certificateable?.name }}</a>

                                      </h4>
                                      <p class="ml-4 text-sm font-medium text-gray-900">
                                        <v-button @click="downloadCertificate(course.id, 'course')" type="button"
                                          class="font-medium text-xs mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                          </svg>
                                          Certificate
                                        </v-button>
                                        <v-button @click="resend(course.certificateable_id, 'course')" type="button"
                                          class="font-medium text-xs bg-transparent border-untitled-gray-200"> <svg
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                          </svg>

                                          Resend
                                        </v-button>
                                      </p>
                                    </div>
                                  </div>

                                  <div class="flex flex-1 items-end justify-between pt-5">
                                    <p @click="gotoUnit(course.certificateable.first_unit.id)"
                                      class="cursor-pointer flex items-center space-x-2 text-sm text-gray-700">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                      </svg>
                                      Open Course
                                    </p>
                                    <div class="ml-4 text-xs ">

                                      <p class="text-xs text-right">Certification Date: <br />{{
                                        formatDate(course.created_at) }}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div v-else class="text-center">
                              <p class="text-untitled-gray-500">No course certification.
                              </p>
                            </div>
                          </div>

                        </div>
                        <div :class="{ hidden: openTab !== 2, block: openTab === 2 }">
                          <LoadingState v-if="loading" class="mx-auto text-center" />
                          <div v-else>
                            <ul role="list" v-if="filteredModuleList.length > 0" class="overflow-auto"
                              :style="filteredModuleList.length < 4 ? 'height:auto' : 'height: 600px;'">
                              <li class="grid lg:flex md:flex py-6 px-4 shadow-md" v-for="data in filteredModuleList"
                                :key="data.id">


                                <div class="ml-0 lg:ml-4 md:ml-4 mt-2 flex flex-1 flex-col sm:ml-6">
                                  <div>
                                    <div class="flex justify-between">
                                      <h4 class="text-sm" v-if="data.certificateable">
                                        <a href="#" class="font-medium text-gray-700 hover:text-gray-800">{{
                                          data.certificateable?.name }}</a>

                                      </h4>
                                      <p class="ml-4 text-sm font-medium text-gray-900">
                                        <v-button @click="downloadCertificate(data.id, 'module')" type="button"
                                          class="font-medium text-xs mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                          </svg>
                                          Certificate
                                        </v-button>
                                        <v-button @click="resend(data.certificateable_id, 'module')" type="button"
                                          class="font-medium text-xs bg-transparent border-untitled-gray-200">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                          </svg>

                                          Resend
                                        </v-button>
                                      </p>
                                    </div>
                                  </div>

                                  <div class="flex justify-between pt-5">

                                    <p @click="gotoUnit(data.certificateable?.first_unit?.id)"
                                      class="cursor-pointer flex items-center space-x-2 text-sm text-gray-700">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                      </svg>
                                      Open Module
                                    </p>
                                    <p class="text-xs text-right">Certification Date: <br />{{ formatDate(data.created_at)
                                    }}</p>
                                  </div>

                                </div>
                              </li>
                            </ul>
                            <div v-else class="text-center">
                              <p class="text-untitled-gray-500">No module certification.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div :class="{ hidden: openTab !== 3, block: openTab === 3 }">
                          <LoadingState v-if="loading" class="mx-auto text-center" />
                          <div v-else>
                            <ul v-if="filteredPlaylists.length > 0" role="list" class="overflow-auto"
                              :style="filteredPlaylists.length < 4 ? 'height:auto' : 'height: 600px;'">
                              <li class="grid lg:flex md:flex py-6 px-4 shadow-md" v-for="data in filteredPlaylists"
                                :key="data.id">


                                <div class="ml-0 lg:ml-4 md:ml-4 mt-2 flex flex-1 flex-col sm:ml-6">
                                  <div>
                                    <div class="flex justify-between">
                                      <h4 class="text-sm">
                                        <a href="#" class="font-medium text-gray-700 hover:text-gray-800">{{
                                          data.certificateable?.name }}</a>

                                      </h4>
                                      <p class="ml-4 text-sm font-medium text-gray-900">
                                        <v-button @click="downloadCertificate(data.id, 'playlist')" type="button"
                                          class="font-medium text-xs mr-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                          </svg>
                                          Certificate
                                        </v-button>

                                        <v-button @click="resend(data.certificateable_id, 'playlist')" type="button"
                                          class="font-medium text-xs bg-transparent border-untitled-gray-200">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                          </svg>

                                          Resend
                                        </v-button>
                                      </p>
                                    </div>
                                  </div>

                                  <div class="flex justify-between pt-5">

                                    <p @click="gotoUnit(data.certificateable?.first_unit?.id, data.certificateable_id)"
                                      class="cursor-pointer flex items-center space-x-2 text-sm text-gray-700">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                      </svg>
                                      Open Playlist
                                    </p>
                                    <p class="text-xs text-right">Certification Date: <br />{{ formatDate(data.created_at)
                                    }}</p>
                                  </div>

                                </div>
                              </li>
                            </ul>
                            <div v-else class="text-center">
                              <p class="text-untitled-gray-500">No playlist certification.
                              </p>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
</TransitionRoot>
</template>

<script setup>
import { ref, inject, computed, defineEmits, onMounted, watch, defineProps } from "vue";
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import LoadingState from '@/components/partials/LoadingState.vue';
import NotificationDialog from '@/components/partials/NotificationDialog.vue';
import { useRouter } from 'vue-router';
import Bugsnag from '@bugsnag/js';
import { VButton } from 'revdojo-vue-components'
import ECombobox from "@/components/element-components/ECombobox";
import formatDate from "@/composable/formatDate";
import { useAuthStore } from '@/stores';

const props = defineProps({
  user: {
    type: Object,
    default: null
  }
})

const open = ref(true);
const openTab = ref(0);
const axios = inject("axios");
const emit = defineEmits(["close"]);
const router = useRouter();
const showNotificationDialog = ref(false);
const resending = ref(false);
const loading = ref(false);
const auth = useAuthStore();

const courses = ref([]);
const modules = ref([]);
const playlists = ref([]);
const curriculums = ref([]);

const sortBy = ref(null);
const sorts = ref([
  {
    name: 'A-Z',
    value: 'a-z'
  },
  {
    name: 'Z-A',
    value: 'z-a'
  },
  {
    name: 'Newest to Oldest',
    value: 'newest'
  },
  {
    name: 'Oldest to Newest',
    value: 'oldest'
  }
]);


function changeSort(sortBy) {
  switch (openTab.value) {
    case 0:
      sorting(curriculums.value, sortBy);
      break;
    case 1:
      sorting(courses.value, sortBy);
      break;
    case 2:
      sorting(modules.value, sortBy);
      break;
    case 3:
      sorting(playlists.value, sortBy);
      break;
  }
}

watch(sortBy, (val) => {
  changeSort(val);
});

watch(openTab, () => {
  changeSort(sortBy.value);
});

const sorting = (contents, sortBy) => {
  const sortData = ref(contents);
  sortData.value.sort((a, b) => {
    switch (sortBy) {
      case 'default':
        return new Date(b.created_at) - new Date(a.created_at);
      case 'a-z':
        return a.certificateable?.name.localeCompare(b.certificateable?.name);
      case 'z-a':
        return b.certificateable?.name.localeCompare(a.certificateable?.name);
      case 'newest':
        return new Date(b.created_at) - new Date(a.created_at);
      case 'oldest':
        return new Date(a.created_at) - new Date(b.created_at);

    }
  });

  switch (openTab.value) {
    case 1:
      filteredCourseList.value = sortData.value;
      break;
    case 2:
      filteredModuleList.value = sortData.value;
      break;
    case 3:
      playlists.value = sortData.value;
      break;
  }
}

const filteredCourseList = computed(() => {
  return courses.value ?? [];
});

const filteredModuleList = computed(() => {
  return modules.value ?? [];
});

const filteredPlaylists = computed(() => {
  return playlists.value ?? [];
});

const filteredCurriculums = computed(() => {
  return curriculums.value ?? [];
});

function toggleTabs(tab) {
  openTab.value = tab;
}

const sendCertificate = async (id, type) => {
  await axios.post(`/api/download/certificate`,
    {
      userId: auth.user?.id,
      id: id,
      type: type
    }
  ).then((response) => {
    window.open(response.data, '_blank');
  }).catch((error) => {
    Bugsnag.notify(error);
  })
};

const resendCertificate = async (id, type) => {
  resending.value = true;
  await axios.put(`/api/send-certificate`,
    {
      id: id,
      type: type
    }
  ).then((response) => {
    Bugsnag.notify(response.data);
    showNotificationDialog.value = true;
    resending.value = false;
  }).catch((error) => {
    Bugsnag.notify(error);
    resending.value = false;
  }).finally(() => {
    resending.value = false;
  });
};

const fetchAssignPlaylist = async () => {
  await axios.get(`/api/fetchAssignedPlaylist`, {
    params: {
      type: 'certified'
    }
  }).then((response) => {
    playlists.value = response.data;
  }).catch((error) => {
    Bugsnag.notify(error);
  })
};

fetchAssignPlaylist();

function downloadCertificate(id, type) {
  sendCertificate(id, type);
}

function resend(id, type) {
  resendCertificate(id, type);
}
const close = () => {
  open.value = false;
  emit("close", open.value);
};


function gotoUnit(unitId, playlistId = null) {
  if (playlistId) {
    router.push(`/units/${unitId}/${playlistId}`);
    return;
  }

  router.push(`/units/${unitId}`);
}

function closeNotificationDialog() {
  showNotificationDialog.value = false;
}

const fetchCertificates = async () => {
  loading.value = true;
  let param = props.user ? `/${props.user.user_id}`:'';
  await axios.get('/api/v3/user/my-progress/certificates-earned'+ param).then((response) => {
    courses.value = response.data.course_certificates;
    modules.value = response.data.module_certificates;
    playlists.value = response.data.playlist_certificates;
    curriculums.value = response.data.curriculum_certificates;
    loading.value = false;
  }).catch((error) => {
    Bugsnag.notify(error);
    loading.value = false;
  }).finally(() => {
    loading.value = false;
  });
}
onMounted(() => {
  fetchCertificates();
});
</script>
